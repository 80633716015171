import { ChangeEvent, useState } from "react";

import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import Paginator from "../../../../../../components/Pagination/Pagination.component";
import ButtonLoader, { Loader } from "../../../../../../UI/Loaders/Loaders";
import { useCustomerBankDetails } from "../../../../../../custom-hooks/useCustomerBankDetails";
import { formatMoney } from "../../../../../../helpers/formatter";
import useBankAccountDetails from "../../../../../../custom-hooks/useBankAccountDetails";
import CustomSearchDropDown from "../../../../../../components/CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { TBankAccountDetails } from "../type";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { getTokenFromLocalStorage } from "../../../../../../helpers/localStorage";
import { BASE_URL } from "../../../../../../appConstants";
import { appInsights } from "../../../../../../config/appInsights";

const BankStatement = ({ properties }: any) => {
  const [searchQuery, setSearchQuery] = useState({
    CustomerId: properties?.dataRecord?.borrower_details?.customer_id,
    Page: 1,
    PageSize: 20,
    AccountNumber: "",
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [currency, setCurrency] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [inputSearch, setInputSearch] = useState({
    Page: 1,
    PageSize: 20,
    DateFrom: "",
    DateTo: "",
  });

  const {
    data: bankStatementDetailsData,
    isLoading,
    error,
  } = useCustomerBankDetails(searchQuery);

  const { data: bankAccountDetails } = useBankAccountDetails(
    properties?.dataRecord?.borrower_details?.customer_id
  );

  const cleanupBankAccountList = (
    _bankAccountDetails: TBankAccountDetails[] | undefined
  ) => {
    if (_bankAccountDetails && _bankAccountDetails.length > 0) {
      let result = _bankAccountDetails.map((_item) => {
        return {
          label: `${_item?.accountNo}/${_item?.accountName}/${_item?.bankName}/${_item?.currency}`,
          value: _item?.accountNo,
          currency: _item?.currency,
          bankName: _item?.bankName,
          accountName: _item?.accountName,
        };
      });

      return result;
    }

    return [];
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    return setInputSearch((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        Page: 1,
      };
    });
  };

  const handleChangeBankAccount = (data: any) => {
    setAccountName(data?.accountName);
    setAccountNo(data?.value);
    setBankName(data?.bankName);
    setInputSearch((prev) => {
      return {
        ...prev,
        AccountNumber: data?.value,
      };
    });
  };

  const handleSearch = () => {
    setSearchQuery((prev) => {
      return {
        ...prev,
        ...inputSearch,
      };
    });
  };

  const handleDownloadAccountStatistics = () => {
    setIsDownloading(true);

    let token = getTokenFromLocalStorage();

    const downloadParams = {
      customer_id: properties?.dataRecord?.borrower_details?.customer_id,
      account_number: accountNo,
      from_date: inputSearch?.DateFrom,
      to_date: inputSearch?.DateTo,
      account_name: accountName,
    };

    fetch(
      BASE_URL +
        `${ajaxEndpoints.GET_BANK_STATEMENT}?${new URLSearchParams(
          downloadParams
        )}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
      }
    )
      .then((response) => {
        setIsDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `bank statement from ${inputSearch?.DateFrom} to ${inputSearch?.DateTo}.csv`
            );
            link.click();
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "BankStatement.tsx",
          },
        });
        setIsDownloading(false);
      });
  };

  let RenderedComponent = null;
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to fetch Bank Statement
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader text="Loading bank statement ..." centered={true} />
    );
  } else if (bankStatementDetailsData) {
    if (bankStatementDetailsData?.transactions.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Bank Statement data
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Transaction ID</th>
                <th>Transaction Type</th>
                <th>Type</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Balance</th>
                <th>Narration</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {bankStatementDetailsData?.transactions.map(
                (_data: any, _id: number) => {
                  const {
                    transactionId,
                    transactionType,
                    type,
                    date,
                    narration,
                    amount,
                    balance,
                    currency,
                  } = _data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{transactionId}</td>
                      <td>{transactionType}</td>
                      <td>{type}</td>
                      <td>{date?.substring(0, 10)}</td>
                      <td>{formatMoney(amount)}</td>
                      <td>{formatMoney(balance)}</td>
                      <td>{narration}</td>
                    </tr>
                  );
                }
              )}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={searchQuery?.PageSize}
            page={searchQuery?.Page}
            count={bankStatementDetailsData?.totalCount}
            changeCurrentPage={(currentPage: number) =>
              setSearchQuery((prev) => ({ ...prev, Page: currentPage }))
            }
          />
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="flex-row">
          <div className="d-flex">
            <div className="mr-4 d-flex">
              <span
                style={{
                  display: "inline-block",
                  minWidth: "6rem",
                }}
              >
                Start Date:
              </span>
              <input
                className="user__form form-control"
                name="DateFrom"
                type="date"
                onChange={handleInputChange}
                style={{
                  height: "2.55rem",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div className="mr-4 d-flex">
              <span
                style={{
                  display: "inline-block",
                  minWidth: "6rem",
                }}
              >
                End Date:
              </span>
              <input
                className="user__form form-control"
                name="DateTo"
                type="date"
                onChange={handleInputChange}
                style={{
                  height: "2.55rem",
                  borderRadius: "5px",
                }}
              />
            </div>
            <div>
              <label htmlFor="table-fetch" className="form-group">
                No. of results:{" "}
                <select
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  name="PageSize"
                  id="table-fetch"
                  className="table-fetch-select"
                  value={inputSearch.PageSize}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div
              style={{
                width: "15rem",
                paddingTop: "1.1rem",
              }}
            >
              <CustomSearchDropDown
                label="Select Aggregator"
                defaultOptions={cleanupBankAccountList(bankAccountDetails)}
                onChange={handleChangeBankAccount}
                isShowLabel={false}
                inputHeight={40}
                isSearchable={false}
              />
            </div>
            <button
              className="create btn m-3 advancly-btn"
              onClick={handleSearch}
            >
              <i className="fas fa-search m-r-1" />
              Search
            </button>
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className="create btn advancly-btn"
              onClick={handleDownloadAccountStatistics}
            >
              <i className="fas fa-download m-r-1" />
              Download
              {isDownloading && <ButtonLoader />}
            </button>
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-between">
            <span className="mr-3">
              Opening Balance:{" "}
              <strong>
                {bankStatementDetailsData?.currency &&
                  `${bankStatementDetailsData?.currency}${formatMoney(
                    bankStatementDetailsData?.openingBalance
                  )}`}
              </strong>
            </span>
            <span>
              Total Credit:{" "}
              <strong>
                {bankStatementDetailsData?.currency &&
                  `${bankStatementDetailsData?.currency}${formatMoney(
                    bankStatementDetailsData?.totalCredit
                  )}`}
              </strong>
            </span>
          </div>

          <div className="d-flex justify-content-between">
            <span className="mr-3">
              Closing Balance:{" "}
              <strong>
                {bankStatementDetailsData?.currency &&
                  `${bankStatementDetailsData?.currency}${formatMoney(
                    bankStatementDetailsData?.closingBalance
                  )}`}
              </strong>
            </span>
            <span>
              Total Debit:{" "}
              <strong>
                {bankStatementDetailsData?.currency &&
                  `${bankStatementDetailsData?.currency}${formatMoney(
                    bankStatementDetailsData?.totalDebit
                  )}`}
              </strong>
            </span>
          </div>
        </div>

        <div
          style={{
            height: "25rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default BankStatement;
